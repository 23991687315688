.NearMe .container {
    display: grid;
    margin: 0 20px;
    gap: 20px;
}

.NearMe .container .container-content {
    background-color: grey;
    height: 38vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (min-width: 600px) {
    .NearMe .container {
        grid-template-columns: repeat(2, 1fr);
    }
}