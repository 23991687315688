.calendarTitle {
    padding-bottom: 2px;
    color: grey;
    font-size: 18px;
    margin: 0;
    text-align: start;
    margin-left: 2%;
  }

.calendarOne {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    box-shadow: 0 0 8px silver;
    margin: 5px 0;
    padding: 5px;
  }

.calendarContent {
    display: flex;
    max-width: 100%;
    flex-basis: 420px;
    flex-direction: column;
    flex-grow: 100;
    align-items: stretch;
    padding: 1%;
    }

.react-calendar__navigation__arrow {
    font-size: 16px;
    height: 26px;
    padding: 0 15px;
    border: none;
    color: rgb(90, 90, 250);
}

.react-calendar__navigation__label {
    font-size: 14px;
    font-weight: bold;
    border: none;
    height: 26px;
}

.react-calendar__tile {
    font-size: 16px;
    margin-bottom: 6px;
    color: grey;
    border: none;
    background: none;
}

.react-calendar__tile--active {
    font-size: 18px;
    margin-bottom: 6px;
    border: none;
    text-decoration: none;
    color: white;
    width: 22px;
    height: 22px;
    background: rgb(90, 90, 250);
}

.react-calendar__month-view__weekdays__weekday {
    font-weight: bold;
    font-size: 14px;
}

.react-calendar__month-view__weekdays {
    margin-top: 10px;
    font-size: 10px;
    padding: 5px;
    }

.react-calendar__month-view__days {
    margin-top: 5px;
}

.calendarWorkoutContainer .ContainerContent {
    display: flex;
    flex-direction: column;
    position: relative; 
}

.ContainerContent .title {
    color: grey;
    margin: 4% 0 0 2%;
    text-align: left;
    font-size: 18px;
    
}

.ContainerContent .button {
    background-color: rgb(7, 168, 7);
    color: white;
    font-size: 14px;
    font-weight: bold;
    position: absolute;
    padding: 3px 10px;
    width: 90px;
    border-radius: 35px;
    right: 3%;
    top: 3%;
}

.calendarExerciseList {
    text-align: left;		
    margin: 3%;
    margin-bottom: 0;
    overflow: scroll;
    overflow-y: scroll;
    height: 380px;
    -ms-overflow-style: none;
}

.calendarExerciseList::-webkit-scrollbar {
    display: none;
  }