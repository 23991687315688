.WorkoutCurrent .WorkoutCurrentTitle {
    text-align: left;
    margin: 30px 20px;
}

.WorkoutCurrent .containerOne {
    display: grid;
    margin: 20px;
}

.WorkoutCurrent .workoutAction {
    background-color: grey;
    height: 54vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.WorkoutCurrent .timer {
    display: grid;
    margin: 40px 10px;
}

.WorkoutCurrent .containerTwo {
    display: flex;
    justify-content: flex-end;
    margin: 20px 0px;
}

.WorkoutCurrent .timerButton {
    border: none;
    background-color: transparent;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    text-transform: uppercase;
}

@media (min-width: 600px) {
    .WorkoutCurrent .containerOne {
        grid-template-columns: 2fr 1fr;
    }

    .containerOne-content:last-child {
        align-self: center;
    }

    .WorkoutCurrent .containerTwo {
        justify-content: center;
    }
}