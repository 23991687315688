.Login button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    outline: none;
}

.Login input {
    padding: 10px;
}

.Login .logoBox {
    margin: 50px;
    display: flex;
    justify-content: center;
}

.Login .logo {
    width: 150px;
}

.Login .container {
    margin: 50px;
}

.Login .inputBox-content {
    margin: 10px;
    display: flex;
    justify-content: center;
}

.Login .loginButtons {
    display: grid;
    justify-content: center;
    gap: 10px;
}

.Login .loginButtons > * {
    text-transform: uppercase;
}

.Login .signInButton {
    font-size: 16px;
    font-weight: bold;
}

.Login .signUpButton {
    font-size: 12px;
    color: grey;
}
