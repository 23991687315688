.NearMeTrainers .containerOne {
    padding: 0 20px;
    display: grid;
}

.NearMeTrainers .containerOne-content.map {
    background-color: grey;
    width: 100%;
    height: 45vh;
}

.NearMeTrainers .listContent {
    /* border: 2px solid pink; */
    display: grid;
    padding-left: 0;
    margin: 20px;
}

.NearMeTrainers .containerTwo.listContent-item {
    /* border: 2px solid aqua; */
    list-style-type: none;
    display: flex;
    align-items: center;
}

.NearMeTrainers .flex-item {
    flex-basis: 1;
    padding: 10px;
}

.NearMeTrainers .trainerImage {
    border-radius: 100%;
    width: 40px;
    height: 40px;
    margin: 0 20px;
    background-color: grey;
}

@media (min-width: 600px) {
    .NearMeTrainers .containerOne {
        grid-template-columns: 2fr 1fr;
    }
}
