.NearMeGyms .containerOne {
    /* border: 2px solid; */
    padding: 0 20px;
    display: grid;
}

.NearMeGyms .containerOne-content.map {
    background-color: grey;
    width: 100%;
    height: 60vh;
}

.NearMeGyms .listContent {
    /* border: 2px solid pink; */
    display: grid;
    padding-left: 0;
}

.NearMeGyms .listContent-item {
    /* border: 2px solid aqua; */
    list-style-type: none;
}

@media (min-width: 600px) {
    .NearMeGyms .containerOne {
        grid-template-columns: 2fr 1fr;
    }
}