.Timer {
    display: flex;
    justify-content: center;
    margin: 50px;
}

.Timer .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px 6px;
}

.Timer .digit-timer {
    grid-column: 1 / 4;
    justify-self: center;
    font-size: 120px;
}

.Timer input {
    width: 37px;
}

.Timer span {
    padding-right: 5px;
}

.Timer .column {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Timer input {
    width: 108px;
    height: 77px;
    font-size: 65px;
    text-align: center;
    padding-left: 2px;
}

.Timer .center {
    justify-self: center;
}

.Timer .buttons {
    grid-column: 1 / 4;
}

.Timer .inline {
    display: inline-block;
    margin: 12px 21px;
}

.Timer .button {
    width: 84px;
    height: 81px;
    border-radius: 134px;
    font-size: 42px;
    padding-top: 12px;
    padding-left: 8px;
}